<template>
<template v-if="init">
  <template v-if="torder_error">
    <div style="flex-direction:column;background-color:#333;width:100%;height:100%;justify-content:center;display:flex">
      <div style="text-align: center;color: white;font-size: 25px;padding: 60px 10px 10px 10px;font-weight: bold;">
        <div style="padding-bottom: 8px">
          {{ $t('timeout_message') }}
        </div>
        <div>
          {{ $t('rescan_qr') }}
        </div>
      </div>
      <div style="align-self:center;background:url(images/scan_again.png);width:80%;height:80%;background-repeat:no-repeat;background-position:center center;background-size: contain;">
      </div>
    </div>
  </template>
  <template v-else>
    <div style="position: absolute;left:0;top:0;width: 100%;height: 100%;z-index: 999999;background-color: #00000099;display: flex;justify-content: center" v-if="loading">
      <div style="align-self: center">
        <pulse-loader :loading="loading" :color="'#ff1b8'" :size="'40px'"></pulse-loader>
      </div>
    </div>
    <div @wheel="otherWheel" style="height: 100%;display: flex;flex-direction: column" :class="shop_uid ? 'smart_order' : 'kiosk'">
      <notice-container v-if="shop_uid && !agent_pos" />
      <div v-if="!hide_info && !torder_status && torder_no && !torder_error" :style="'display: flex;color:white;padding:8px 12px;background-color: var(--qro-primary-color);text-align: center;border-radius: 0 0 10px 10px;font-size:'+default_font_size+'em;'">
        <div>
          <div>{{ $t('table') }}</div>
          <div style="font-size: 32px;font-weight: bold">
            {{ my_table_no }}
          </div>
        </div>
        <div v-if="!tablet_auth && torder_type == 'pass'" style="margin-left: 8px;display: flex;flex-direction: column" @click="change_torder_pass">
          <div>{{ $t('auth_number') }}</div>
          <div style="flex:1;font-size: 22px;font-weight: bold;display: flex;justify-content: center">
            <div style="align-self: center">
              {{ torder_pass }}
            </div>
          </div>
        </div>
        <div v-else-if="!prepay && !tablet_auth && torder_type == 'phone'" style="margin-left: 8px;display: flex;flex-direction: column" @click="change_torder_pass">
          <div>{{ $t('phone_number') }}</div>
          <div style="flex:1;font-size: 12px;font-weight: bold;display: flex;justify-content: center">
            <div style="align-self: center;text-align: center" v-html="get_my_torder_phone">
            </div>
          </div>
        </div>
        <div style="flex-grow: 1"></div>
        <div v-if="tablet_auth && !tablet_prepay" style="align-self: center;margin-right: 8px">
          <button :style="tablet_auth ? 'height: 55px;font-size: 1.1em' : ''" @click="qr_open" class="mbutton button button-grey button-border">
            {{ $t('qr_order') }}
          </button>
        </div>
        <div style="align-self: center;">
          <button :style="tablet_auth ? 'height: 55px;font-size: 1.1em' : ''" @click="noti_shop" class="mbutton button button-grey button-border">
            <svg style="fill:#666;height:14px;vertical-align: middle" xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M112 192a24 24 0 0 0 -24-24H24a24 24 0 0 0 0 48H88A24 24 0 0 0 112 192zM51.7 64.8l55.4 32a24 24 0 1 0 24-41.6l-55.4-32a24 24 0 1 0 -24 41.6zM520.9 100a23.8 23.8 0 0 0 12-3.2l55.4-32a24 24 0 0 0 -24-41.6l-55.4 32a24 24 0 0 0 12 44.8zM320 512a64 64 0 0 0 64-64H256A64 64 0 0 0 320 512zM616 168H552a24 24 0 0 0 0 48h64a24 24 0 0 0 0-48zM479.9 208c0-77.7-54.5-139.9-127.9-155.2V32a32 32 0 1 0 -64 0V52.8C214.6 68.1 160.1 130.3 160.1 208c0 102.3-36.1 133.5-55.5 154.3A31.3 31.3 0 0 0 96 384c.1 16.4 13 32 32.1 32H511.9c19.1 0 32-15.6 32.1-32a31.2 31.2 0 0 0 -8.6-21.7C516.1 341.5 479.9 310.3 479.9 208z"/></svg>
            <span v-if="lang_current_code=='ko' || lang_current_code=='en' || lang_current_code=='zh'">
              {{ $t('call_staff') }}
            </span>
          </button>
        </div>
        <div style="align-self: center;margin-left: 8px">
          <button :style="tablet_auth ? 'height: 55px;font-size: 1.1em' : ''" @click="my_order" class="mbutton button button-grey button-border">
            <svg style="fill:#666;height:14px;vertical-align: middle" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M344 288H104c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8zM400.8 5.7L357.3 37 318.7 9.2c-16.8-12.1-39.2-12.1-56.1 0L224 37 185.4 9.2a47.9 47.9 0 0 0 -56.1 0L90.7 37 47.2 5.7C27.4-8.5 0 5.6 0 29.9v452.3c0 23.8 27.1 38.6 47.2 24.2L90.7 475l38.6 27.8c16.8 12.1 39.2 12.1 56.1 0L224 475l38.6 27.8c16.8 12.1 39.3 12.1 56.1 0l38.6-27.8 43.5 31.3c19.8 14.2 47.2 .1 47.2-24.1V29.9C448 6 420.9-8.7 400.8 5.7zm-.8 440.8l-42.7-30.7-66.7 48-66.7-48-66.7 48-66.7-48L48 446.5v-381l42.7 30.7 66.7-48 66.7 48 66.7-48 66.7 48L400 65.5v381zM344 176H104c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8z"/></svg>
            {{ $t('order_list') }}
          </button>
        </div>
      </div>

      <transition name="fade">
        <div v-if="((!tablet_auth && scroll_top<=0) || tablet_auth) && !hide_info && !torder_status && !smart_checkout && !admin_pos && shop_uid && !torder_error /* && info_detail*/"
             :style="tablet_auth?'min-height:'+(80)+'px;max-height:'+(80)+'px'+';position:relative;;display: flex;justify-content: center':'min-height:'+(80-scroll_top)+'px;max-height:'+(80-scroll_top)+'px'+';position:relative;;display: flex;justify-content: center'"
        >
          <div style="align-self: center;height: 80%;display: flex">
            <img v-if="shopinfo && shopinfo.logo" :src="'https://cdnpxy0.mocoplex.com/resize_w/400/'+shopinfo.logo" @click="goRoot" style="cursor: pointer;margin: auto;height: 90%">
            <div v-else-if="shopinfo" v-text="shopinfo.name" style="align-self: center;font-size: 22px;font-weight: bold;text-space: 1" @click="goRoot">
            </div>
          </div>

          <!-- 다국어 //-->
          <div style="position: absolute;right: 10px;top:0px;cursor:pointer"
               v-if="lang_default_code && lang_codes && get_current_lang"
               @click="show_lang"
          >
            <div style="background-color: #333;color: #fff;padding: 8px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
              <svg fill="white" style="height:14px;vertical-align: middle;margin-right: 4px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z"/></svg>
              {{ get_current_lang }}
            </div>
          </div>
        </div>

        <div v-else-if="isTabletMain && tablet_main_top_height>0">
          <!-- 다국어 //-->
          <div :style="'position: absolute;right:10px;top:' + tablet_main_top_height + 'px;cursor:pointer;z-index:2;'"
               v-if="lang_default_code && lang_codes && get_current_lang"
               @click="show_lang"
          >
            <div style="background-color: #333;color: #fff;padding: 8px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
              <svg fill="white" style="height:14px;vertical-align: middle;margin-right: 4px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z"/></svg>
              {{ get_current_lang }}
            </div>
          </div>
        </div>
        <div v-else-if="kiosk_mode">
          <div style="position: absolute;right: 10px;top:0px;cursor:pointer;z-index:1;"
               v-if="lang_default_code && lang_codes && get_current_lang"
               @click="show_lang"
          >
            <div style="background-color: #333;color: #fff;padding: 8px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
              <svg fill="white" style="height:14px;vertical-align: middle;margin-right: 4px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z"/></svg>
              {{ get_current_lang2 }}
            </div>
          </div>
        </div>
      </transition>
      <!-- 다국어 //-->

      <div :style="simple_menu ? 'flex-grow: 1;overflow: auto' : 'flex-grow: 1;overflow: hidden'">
        <router-view v-if="parsing_ok"/>
      </div>
      <div v-if="!hide_footer && !hide_info && !torder_status && !torder_no && !simple_menu && shop_uid && !smart_checkout && !admin_pos && shopinfo && !torder_error" :style="!info_detail ? 'color:#999;min-height:35px;max-height:35px;display: flex;background-color: #efefef;padding:0 12px 0 12px;' : 'color:#999;min-height:65px;max-height:65px;display: flex;background-color: #efefef;padding:0 12px 0px 12px;'">
        <div style="align-self: center;display: flex;width: 100%;font-size: 10px;padding: 7px 0;">
          <div v-if="info_detail">
            {{ shopinfo.corpname }} &nbsp;&nbsp;&nbsp;&nbsp; 사업자번호 : {{ shopinfo.idnum }}
            <br/>
            <span v-if="shopinfo.idnum_singo && shopinfo.idnum_singo != '1111'">
              통신판매업신고 : {{ shopinfo.idnum_singo }}
              <br/>
            </span>
            대표 : {{ shopinfo.ceo }}
            <br/>
            {{ shopinfo.address }}
            <br/>
            연락처 : {{ shopinfo.tel }}&nbsp; ({{ shopinfo.email }})
          </div>
          <div v-else>
            {{ shopinfo.corpname }}
            <br/>
            연락처 : {{ shopinfo.tel }}&nbsp; ({{ shopinfo.email }})
          </div>
          <div style="flex-grow: 1"></div>
          <div style="width:70px">
            <div class="buttons">
              <div @click="info_detail=!info_detail" class="cancel" style="padding: 6px;border: 0px;background-color: #efefef;color:#999;border-radius: 0px;">
                <span v-if="info_detail" style="border: 1px #999 solid;padding: 5px;border-radius: 5px;background-color: white">
                  {{ $t('view_more') }}
                </span>
                <span v-else style="border-bottom: 1px #999 solid;padding-bottom: 5px">
                  {{ $t('default_mode') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <custom-dialog/>
      <close-dialog/>
      <confirm-popup/>
      <dis-closed-dialog/>
      <table-order-key-pad ref="torder"/>
      <alarm-popup ref="alarm"/>
      <lang-popup ref="lang"/>
      <my-order-popup ref="order"/>
      <table-order-confirm-key-pad/>
    </div>
  </template>
</template>
</template>


<script>
import { mapGetters } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import ConfirmPopup from "@/components/popup/ConfirmPopup";
import TableOrderKeyPad from "@/components/popup/TableOrderKeyPad";
import TableOrderConfirmKeyPad from "@/components/popup/TableOrderConfirmKeyPad";
import AlarmPopup from "@/components/popup/AlarmPopup";
import LangPopup from "@/components/popup/LangPopup.vue";
import MyOrderPopup from "@/components/popup/MyOrderPopup";
import CloseDialog from "@/components/common/CloseDialog";
import DisClosedDialog from "@/components/common/DisClosedDialog";
import CustomDialog from "@/components/common/CustomDialog";
import NoticeContainer from "@/components/common/NoticeContainer";
import { loadScript } from "vue-plugin-load-script";
import axios from "axios";
import { ref } from "vue";
import store from "@/store";

export default {
  setup() {
    var torder = ref();
    var alarm = ref();
    var order = ref();
    var lang = ref();
    return {
      torder,
      alarm,
      order,
      lang
    };
  },
  name: 'App',
  computed: {
    isTabletMain() {
      return (this.$route.path.indexOf('/tablet_main')>=0 || this.$route.path.indexOf('/waiting_main')>=0|| this.$route.path.indexOf('/tablet_game')>=0);
    },
    get_current_lang:function () {

      let saved_code = this.$cookie.getCookie2('lang_current_code')
      //lang_current_code
      //

      let name = ''
      let code = this.lang_current_code

      if(saved_code)
        code = saved_code
      if(!code)
        code = this.lang_default_code

      this.lang_codes.forEach(el=>{
        if(el.code == code) {
          name = el.name
        }
      })

      {
        // current
        this.$i18n.locale = code;
      }

      return name
    },
    get_current_lang2:function () {

      let saved_code = localStorage.getItem('lang_current_code')
      //lang_current_code
      //

      let name = ''
      let code = this.lang_current_code

      if(saved_code)
        code = saved_code
      if(!code)
        code = this.lang_default_code

      try {
        this.$app_event("change_lang_code", code);
      }catch (e){}

      this.lang_codes.forEach(el=>{
        if(el.code == code) {
          name = el.name
        }
      })

      {
        // current
        this.$i18n.locale = code;
      }

      return name
    },
    my_table_no: function() {
      var n = this.torder_no;

      try {
        n = parseInt(n);
        n = n.toString();
        if (n.length <= 1)
          n = "0" + n;

        return n;
      } catch (e) {}

      return 0;
    },
    get_my_torder_phone: function() {
      if (!this.torder_phone) {
        return '----';
      }
      var v = this.torder_phone;
      v = v.substring(3);
      var v1 = v.substring(0, 4);
      var v2 = v.substring(4);
      return v1 + '<br/>' + v2;
    },
    ...mapGetters({
      smartorder_mode:'smartorder_mode',
      scroll_top:'scroll_top',
      kiosk_mode:'kiosk_mode',
      force_valid:'force_valid',
      agent_pos: 'agent_pos',
      default_font_size: 'default_font_size',
      presale:'presale',
      tablet_auth: 'tablet_auth',
      hide_info: 'hide_info',
      hide_footer: 'hide_footer',
      t_sid: 't_sid',
      order_details: 'order_details',
      earn_point: 'earn_point',
      torder_error: 'torder_error',
      info: 'shop/info',
      torder_status: 'torder_status',
      torder_no: 'torder_no',
      torder_type: 'torder_type',
      torder_pass: 'torder_pass',
      torder_phone: 'torder_phone',
      menu_page: 'menu_page',
      simple_menu: 'simple_menu',
      flutterWindows: 'flutterWindows',
      posApp: 'posApp',
      smart_checkout: 'smart_checkout',
      admin_pos: 'admin_pos',
      products: 'products',
      myshop_host: 'myshop_host',
      offline_info: 'offline_info',
      shop_uid: 'shop_uid',
      cart_items: 'cart_items',
      parsing_ok: 'parsing_ok',
      prepay: 'prepay',
      tablet_prepay: 'tablet_prepay',
      lang_current_code:'lang_current_code',
      lang_default_code:'lang_default_code',
      lang_codes:'lang_codes',
      tablet_main_top_height:'tablet_main_top_height',
      pay_detail:'pay_detail',
    })
  },
  watch: {
    '$route.path': function() {},
    info_detail: function() {
      this.$cookie.setCookie3('info_detail', this.info_detail ? '1' : '0');
    },
    cart_items: function() {
      if (this.shop_uid) {
        var a = JSON.stringify(this.cart_items);
        localStorage.setItem('cart_v2', a);
      }
    },
    myshop_host: {
      handler() {},
    },
    offline_info: {
      handler() {},
    }
  },
  created() {
    let saved_code = this.$cookie.getCookie2('lang_current_code')

    if(saved_code)
      this.$store.commit('lang_current_code',{value:saved_code})

    var that = this;
    window.read_barcode_global = (code) => {
      try {
        that.$store.commit('scanned_barcode', { value: code });
      } catch (e2) {}
    };

    if (navigator.userAgent.match(/myshop-checkout/i)) {
      this.$store.commit('smart_checkout', { value: true });
    }

    if (navigator.userAgent.indexOf('flutter-windows') >= 0) {
      this.$store.commit('flutterWindows', { value: true });
    }

    if (navigator.userAgent.indexOf('myshop-pos-') >= 0) {
      this.$store.commit('posApp', { value: true });
    }

    if (this.flutterWindows) {
      this.appendFile();
    }
  },
  data: () => ({
    init:true,
    loading: false,
    current_pass: null,
    info_detail: false,
    shopinfo: null,
    tmp_products: null,
    barcode: '',
    interval: null
  }),
  components: {
    LangPopup,
    NoticeContainer,
    PulseLoader,
    MyOrderPopup,
    AlarmPopup,
    TableOrderConfirmKeyPad,
    ConfirmPopup,
    TableOrderKeyPad,
    CloseDialog,
    CustomDialog,
    DisClosedDialog
  },
  beforeUnmount() {
    this.emitter.off('check_tableorder_pass');
    this.emitter.off('my_order_open');
    this.emitter.off('my_order_close');
    this.emitter.off('alarm_open');
    this.emitter.off('change_phone_num_table_order_callback');
    this.emitter.off('block_loading');
    window.removeEventListener('message', this.receiveMessage);
    try {
      window.chrome.webview.removeEventListener('message', this.receiveMessage_pos);
    } catch (e) {}
    if (this.flutterWindows) {
      window.removeEventListener('keydown', this.receiveEvent);
    }

  },
  mounted() {


    var getParameterByName = function(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    // tid - 스마트오더
    // var t_tid = this.$cookie.getCookie2('torder-tid')
    if(getParameterByName('tid')) {
      //let tid = getParameterByName('tid')
      //console.log('t : ' + tid)
      //this.$cookie.setCookie2('torder-tid', tid)
    }


    try {
      let vertical = this.$cookie.getCookie2('tablet_menu_vertical')
      console.log("vertical : " + vertical);
      if (vertical && vertical == "1") {
        this.$store.commit('tablet_menu_vertical', { value: true });
      } else {
        this.$store.commit('tablet_menu_vertical', { value: false });
      }

      if (getParameterByName('agent_pos') && getParameterByName('table_no')) {
        this.$store.commit('admin_table_no', { value: getParameterByName('table_no') });
        this.$store.commit('agent_pos', { value: 1 });
      }
      if (getParameterByName('modify_mode')) {
        this.$store.commit('modify_mode', { value: 1 });
      }
      if (getParameterByName('kiosk_pos_mode')) {
        this.$store.commit('kiosk_pos_mode', { value: 1 });
      }
      if (getParameterByName('kiosk_name')) {
        this.$store.commit('kiosk_name', { value: getParameterByName('kiosk_name') });
      }
      if (getParameterByName('presale')) {
        this.$store.commit('prepay', { value: true });
      }
      if (getParameterByName('tablet_prepay')) {
        this.$store.commit('tablet_prepay', { value: true });
      }
    } catch (e) {
      console.log(e);
    }

    this.emitter.on('block_loading', this.block_loading);
    this.emitter.on('check_tableorder_pass', this.check_tableorder_pass);
    this.emitter.on('my_order_open', this.my_order);
    this.emitter.on('my_order_noti_open', this.my_order_noti);
    this.emitter.on('my_order_close', this.close_my_order);
    this.emitter.on('alarm_open', this.noti_shop);
    this.emitter.on('change_phone_num_table_order_callback', this.change_phone_num_table_order_callback);

    //INSERT INTO shockping.kiosk_manager (device_id, license_pw) VALUES ('e3b0c44298fc', '4433');
    //this.$cookie.setCookie3("KIOSK_ID", "e3b0c44298fc")
    //this.$cookie.setCookie3("KIOSK_PW", "4433")
    //this.$cookie.setCookie3("torder-tid", "44")


    if (this.$cookie.getCookie2('KIOSK_ID') && this.$cookie.getCookie2('KIOSK_PW') && this.$cookie.getCookie2('torder-tid')) {
      let v = {
        KIOSK_ID: this.$cookie.getCookie2('KIOSK_ID'),
        KIOSK_PW: this.$cookie.getCookie2('KIOSK_PW'),
        TABLE_NO: this.$cookie.getCookie2('torder-tid')
      };

      this.$store.commit('default_font_size', { value: 1.4 });

      this.$cookie.setCookie365('KIOSK_ID', v.KIOSK_ID);
      this.$cookie.setCookie365('KIOSK_PW', v.KIOSK_PW);

      this.$store.commit('tablet_auth', { value: v });
      this.$store.commit('torder_no', { value: v.TABLE_NO });
      this.$store.commit('torder_error', { value: false });
    }

    var ty = this.$cookie.getCookie2('mypass_type');
    if (ty) {
      this.$store.commit('torder_type', { value: ty });
    }

    var v = this.$cookie.getCookie2('mypass_number');
    if (v) {
      this.$store.commit('torder_pass', { value: v });
    }
    v = this.$cookie.getCookie2('myphone_number');
    if (v) {
      this.$store.commit('torder_phone', { value: v });
    }

    var isTouchDevice = function() {
      return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    };
    this.$store.commit('touchable', { value: isTouchDevice() });

    this.$store.commit('disable_scan', { value: false });
    this.$store.commit('scanning', { value: false });

    window.addEventListener('message', this.receiveMessage, false);
    try {
      window.chrome.webview.addEventListener('message', this.receiveMessage_pos, false);
    } catch (e) {}
    if (this.flutterWindows) {
      window.addEventListener('keydown', this.receiveEvent, false);
    }

    // smartorder

    this.fetchData();
  },
  methods: {
    qr_open: function() {
      this.emitter.emit("confirm_popup", { qr: true });
    },
    block_loading: function(v) {
      this.loading = v;
    },
    change_phone_num_table_order_callback: function(pass) {
      pass.pass = pass.pass.replaceAll('-', '');
      pass.pass = pass.pass.replaceAll(' ', '');

      var ty = 'users_set_value';

      if (pass.pass.length > 4) {
        ty = 'phone_number';
      }

      var object = {
        'tableorder_id': this.order_details.table_info.tableorder_id,
        'uid': this.shop_uid,
        'password_type': ty,
        'password_text': pass.pass,
        'password_text_old': this.current_pass,
      };
      if (ty == 'phone_number') {
        object['earn_point'] = pass.earn_point;
      }

      var url = '/torder-api/tableorder/table/password/customer';
      this.$http.put(url, object).then(res => {
        this.$toast.success(this.$t('password_changed'), { position: "top" });

        var num = pass.pass;

        if (num.length == 4) {
          this.$cookie.setCookie365('mypass_number', num);
          this.$cookie.setCookie365('mypass_type', 'pass');
          this.$store.commit('torder_type', { value: "pass" });
        } else {
          this.$cookie.setCookie365('myphone_number', num);
          this.$cookie.setCookie365('mypass_type', 'phone');
          this.$store.commit('torder_type', { value: "phone" });
        }
      }).catch(error => {
        this.$toast.error(this.$t('operation_failed'), { position: "top" });
      });
    },
    check_tableorder_pass: function(cb) {
      this.order.check_existed_pass(cb);
    },
    change_torder_pass: function() {
      var that = this;
      var callback = function(b) {
        if (!b) {
          that.$toast.error(that.$t('invalid_auth_number'), { position: "top" });
        } else {
          that.emitter.emit("enter_exist_num_table_order_close");
          that.emitter.emit("change_phone_num_table_order");
        }
      };

      this.emitter.emit("confirm_popup", {
        title: this.$t('change_auth_number'),
        callback: function() {
          that.emitter.emit("enter_exist_num_table_order", function(num) {
            that.current_pass = num;
            that.order.check_my_password('', callback, num);
          });
        }
      });
    },
    my_order: function() {
      this.order.open();
    },
    my_order_noti: function() {
      this.order.open_noti_prepay();
    },
    close_my_order: function() {
      this.order.close();
    },
    show_lang:function () {
      this.lang.open();
    },
    noti_shop: function() {
      this.alarm.open();
    },
    go_largemenu: function() {
      this.$router.push({ path: '/menu' });
    },
    go_simplemenu: function() {
      this.$router.push({ path: '/smenu' });
    },
    appendFile() {
      let file = document.createElement('link');
      file.rel = 'stylesheet';
      file.href = '/css/flutter.css';
      document.head.appendChild(file);
    },
    otherWheel: function(e) {
      if (this.flutterWindows) {
        var y = e.deltaY;
        var timeStamp = e.timeStamp;
        var v = { y: y, timeStamp: timeStamp };
        this.$store.commit('wheelDelta', { value: v });
      }
    },
    goRoot: function() {
      location.href = "/";
    },
    receiveMessage_pos(e) {
      try {
        var o = e.data;

        if (o.cmd == 'kiosk_barcode') {
          this.$store.commit('scanned_barcode', { value: o.value });
        }
      } catch (e2) {}
    },
    receiveEvent(evt) {
      try {
        if (this.interval) clearInterval(this.interval);
        if (evt.code == 'Enter') {
          if (this.barcode) {
            this.$store.commit('scanned_barcode', { value: this.barcode });
          }
          this.barcode = '';
          return;
        }
        if (evt.code != 'Shift') {
          this.barcode += evt.key;
        }
        this.interval = setInterval(() => this.barcode = '', 20);
      } catch (e) {}
    },
    receiveMessage(event) {
      try {
        var json_data = event.data.replace(/\n/g, '\\n').replace(/\t/g, "\\t");
        var data = JSON.parse(json_data);

        if (data.cmd && data.cmd == 'clear_cart') {
          this.$store.commit('cart_items', { value: [] });
        } else if (data.kiosk_updated) {
          this.getOfflineInfo();
        } else if (data.barcode) {
          this.$store.commit('scanned_barcode', { value: data.barcode });
        } else if (data.require_id_status) {
          this.$store.commit('require_id_status', { value: data.require_id_status });
        } else if (data.table_payment_complete) {
          localStorage.setItem('table_order_list', '[]');
        } else if (data.pay_data) {
          this.emitter.emit("payment_close");
          if (data.pay_data.order_info) {
            this.$store.commit('pay_data', { value: data.pay_data });
            if (data.result_data != null) {
              var pi = data.result_data.point_info;
              if (pi.total_point_amount != null) {
                this.$store.commit('total_point_amount', { value: pi.total_point_amount });
              }
            }
            this.emitter.emit("receipt_yn", data);
          }
        } else if (data.pay_game_complete) {
          this.emitter.emit("payment_complete_game");
        } else if (data.alert_msg) {
          this.emitter.emit("alert_msg", data);
        } else if (data.card_alert_msg) {
          this.emitter.emit("card_alert_msg", data);
        } else if (data.point_alert_msg) {
          this.emitter.emit("point_alert_msg", data);
        } else if (data.copy_cart) {
          var decoded = decodeURIComponent(escape(window.atob(data.copy_cart)));
          var jsonCopy = JSON.parse(decoded);
          this.$store.commit('receipt_memo', { value: jsonCopy.receipt_memo });
          this.$store.commit('copy_items', { value: jsonCopy.products });
          if (jsonCopy.type == 'cash' || jsonCopy.type == 'transfer') {
            this.$store.commit('copy_pay_type', { value: jsonCopy.type });
          } else {
            this.$store.commit('copy_pay_type', { value: null });
          }
          this.$router.push({ path: '/cart', query: { menu_mode: true } });
          this.$app_event("audio", "copy_cart_done");
        } else if (data.kiosk_adult_success) {
          this.emitter.emit("approve_id", data.order_id);
        } else if (data.kiosk_adult_failed) {
          this.emitter.emit("refuse_id", data.order_id);
        } else if (data.kiosk_adult_id_check) {
          let obj = {};
          obj.order_id = data.order_id;
          obj.notice_text = data.notice_text;
          this.emitter.emit("check_id", obj);
        } else if (data.payment_with_point) {
          this.emitter.emit("payment_type_with_point", data.payment_with_point);
        } else if (data.kiosk_off_status) {
          if (data.kiosk_off_status == 'CLOSE') {
            this.emitter.emit("close", true);
          } else if (data.kiosk_off_status == 'OPEN') {
            this.emitter.emit("close", false);
          }
        } else if (data.pos_mode) {
          if (data.pos_mode.cash) {
            this.$store.commit('pos_cash', { value: true });
          }
          this.$store.commit('pos_mode', { value: data.pos_mode });
        } else if (data.kiosk_mode) {
          this.$router.push({ path: '/menu' });
        } else if (data.pos_select_menu) {
          this.$router.push({ path: '/menu', query: { pos_mode: true } });
        } else if (data.table_select_menu) {
          this.$router.push({ path: '/menu', query: { table_mode: true } });
        } else if (data.use_point_cancel) {
          this.$store.commit('disable_scan', { value: false });
        } else if (data.tableorder_pay_divide_complete) {
          var pay_dt = this.pay_detail
          var remain = data.remain_amount
          pay_dt.remain_amount = remain
          var supply = Math.round(remain / 1.1);
          pay_dt.amount = {
            total_price: remain,
            supply: supply,
            tax: remain - supply,
            tax_free: 0,
            tip: 0
          }
          this.$store.commit('pay_detail', {value: pay_dt})
          this.emitter.emit("payment_tablet_close")
          this.emitter.emit("payment_tablet_type")
        } else if (data.tableorder_pay_error) {
          this.emitter.emit("payment_tablet_close")
        } else if (data.tableorder_pay_complete) {
          this.emitter.emit("tablet_order_start", data.phone_number);
        } else if (data.tableorder_game_pay_divide_complete) {
          var pay_dt = this.pay_detail
          var remain = data.remain_amount
          pay_dt.remain_amount = remain
          var supply = Math.round(remain / 1.1);
          pay_dt.amount = {
            total_price: remain,
            supply: supply,
            tax: remain - supply,
            tax_free: 0,
            tip: 0
          }
          this.$store.commit('pay_detail', {value: pay_dt})
          this.emitter.emit("payment_tablet_close")
          this.emitter.emit("payment_complete_game")
        } else if (data.tableorder_game_pay_complete) {
          this.emitter.emit("payment_complete_game")
        } else if (data.tableorder_game_pay_error) {
          this.emitter.emit("payment_tablet_cancel")
        }
      } catch (e) {}
    },
    init_offline_info() {
      let url = "/core-api/main/get/offline_info/smartorder?uid=" + this.shopinfo.uid
      axios.get(url).then(res => {
        this.init = true

        var data = res.data;
        this.$store.commit('offline_info', { value: data });

        if (data.apply_sale) {
          this.$store.commit('sale_available', { value: true });
        }
        if (data.apply_review) {
          this.$store.commit('review_available', { value: true });
        }


        // sale_ref
        if (data.sale_ref) {
          
          try {
            var a = JSON.parse(data.sale_ref)
            if(a) {

              if(a.smartorder_opt) {
                this.$store.commit('smartorder_opt', { value: a.smartorder_opt });
              }

              if(a.video_ad) {
                if(!this.smartorder_mode) {
                  this.$store.commit('ad_visible', { value: true });
                } else {
                  //console.log("smartorder_mode")
                }

              }
              if(a.presale) {
                this.$store.commit('prepay', { value: true });
              }

              // color
              if(a.color) {
                let clr = a.color

                //document.documentElement.style.setProperty('--qro-primary-color', '#FF5733');
                document.documentElement.style.setProperty('--qro-primary-color', clr['--qro-primary-color'])
                document.documentElement.style.setProperty('--qro-secondary-color', clr['--qro-secondary-color'])
                document.documentElement.style.setProperty('--qro-alt-color1', clr['--qro-alt-color1'])
              } else {

                let clr = {
                  "--qro-primary-color": "#ff1b81",
                  "--qro-secondary-color": "#4d3750",
                  "--qro-alt-color1": "#FFA500"
                }

                document.documentElement.style.setProperty('--qro-primary-color', clr['--qro-primary-color'])
                document.documentElement.style.setProperty('--qro-secondary-color', clr['--qro-secondary-color'])
                document.documentElement.style.setProperty('--qro-alt-color1', clr['--qro-alt-color1'])

              }
            }
          } catch (e) {
          }
        }
        else {
          let clr = {
            "--qro-primary-color": "#ff1b81",
            "--qro-secondary-color": "#4d3750",
            "--qro-alt-color1": "#FFA500"
          }

          document.documentElement.style.setProperty('--qro-primary-color', clr['--qro-primary-color'])
          document.documentElement.style.setProperty('--qro-secondary-color', clr['--qro-secondary-color'])
          document.documentElement.style.setProperty('--qro-alt-color1', clr['--qro-alt-color1'])
        }

        // 웨이팅은 스킵
        if(this.$route.path.indexOf('/waiting') >= 0 || this.$route.path.indexOf('/review')>=0) {
          this.$store.commit('parsing_ok', { value: true });
          return
        }


        let SMARTORDER_URL = store.state.my_env('kiosk')
        //console.log(this.$route.path)
        if(location.href.indexOf(SMARTORDER_URL)>0) {
          // ok
        }
        else {
            // 여기 확인 필요 - 스마트오더가 아닌 테이블 오더는 허용 필요
            if (

                (!this.agent_pos &&
                // 테이블 번호 설정이 안된경우만..
                !this.torder_no)
                &&
                // valid - true 이면 성공
                !this.force_valid
            ) {
              throw 'invalid';
            }
        }

        this.load_category();
      }).catch(error => {
        this.init = true
        this.$toast.error( this.$t('shop_not_ready'), { position: "top" });

        let clr = {
          "--qro-primary-color": "#ff1b81",
          "--qro-secondary-color": "#4d3750",
          "--qro-alt-color1": "#FFA500"
        }

        document.documentElement.style.setProperty('--qro-primary-color', clr['--qro-primary-color'])
        document.documentElement.style.setProperty('--qro-secondary-color', clr['--qro-secondary-color'])
        document.documentElement.style.setProperty('--qro-alt-color1', clr['--qro-alt-color1'])
      });
    },
    load_category() {

      //var url = '/core-api/category/get/parent?uid=' + this.shopinfo.uid;
      let url = '/core-api/category/get/parent/'+this.lang_current_code+'?uid=' + this.shopinfo.uid;
      axios.get(url).then(res => {
        var data = res.data;
        data.splice(0, 0, {
          cid: 0,
          name: this.$t('all')
        });

        if (!this.agent_pos) {
          for (let i = data.length - 1; i >= 0; i--) {
            let el = data[i];
            if (el.category_restriction == 'ONLY_POS') {
              data.splice(i, 1);
            }
          }
        }

        this.$store.commit('category_data', { value: data });
        this.$store.commit('parsing_ok', { value: true });
      }).catch(error => {
        this.$toast.error(this.$t('shop_not_ready'), { position: "top" });
      });

    },
    load2(host) {

      {
        let url = "/core-api/lang/get/codes?id=" + host;
        axios.get(url).then(res => {
          this.$store.commit('lang_codes', { value: res.data });
        })
      }

      let url = "/core-api/main/get/info/skin?id=" + host;
      axios.get(url).then(res => {
        this.shopinfo = res.data;
        document.title = this.shopinfo.name;
        this.$store.commit('shop_info', { value: this.shopinfo });


        // info
        this.$store.commit('lang_default_code', { value: this.shopinfo.lang_default_code });

        // 현재 선택 코드?
        if(!this.lang_current_code)
          this.$store.commit('lang_current_code',{value:this.shopinfo.lang_default_code})

        if (!this.tablet_auth) {
          this.$store.commit('default_font_size', { value: 1 });
          this.$store.commit('vertical', { value: true });
        }

        this.$store.commit('myshop_host', { value: 'https://m.shockping.com' });
        this.$store.commit('kiosk_host', { value: 'https://kiosk-api.snadx.com' });
        this.$store.commit('shop_uid', { value: this.shopinfo.uid });

        if (this.shopinfo.uid && this.t_sid) {
          if (this.t_sid.toString() == this.shopinfo.uid.toString() || this.hide_info) {
          } else {
            if (this.$route.path.indexOf('torder-error') < 0) {
              this.$router.replace({ path: '/torder-error' });
            }
          }
        }

        if (this.shopinfo.uid)
          this.init_offline_info();
        else
          this.init = true

        try {
          this.$cookie.setCookie3('cart', '');
          var a = localStorage.getItem('cart_v2');

          try {

            // 카트 정보 정리
            let hashed = localStorage.getItem('cart_v2_hash')
            if(hashed) {
              let getMD5Hash = function(string) {
                return CryptoJS.MD5(string).toString();
              }

              // 해쉬된 가격이 있다면?
              let hash = getMD5Hash(a)
              let prevent_delete = false

              if(hash == hashed) {
                // 유효기간이 지났다?
                let lim = parseInt(localStorage.getItem('cart_v2_exp'))
                if(lim) {
                  let d = new Date().getTime()
                  if(d > lim) {
                    // 장바구니가 같고, 시간이 지났다
                    localStorage.removeItem('cart_v2');
                    // exp 삭제 - 시간이 지났다
                    localStorage.removeItem('cart_v2_hash')
                    localStorage.removeItem('cart_v2_exp')
                  } else {
                    // 아직 유효기간이 지나지 않았다
                    prevent_delete = true
                  }
                }
              }

              // 기존 삭제 해시를 지운다
              if(!prevent_delete) {
                a = []
                localStorage.removeItem('cart_v2_exp')
                localStorage.removeItem('cart_v2_hash')
              }
            }

          } catch (ex) {}

          var v = JSON.parse(a);
          this.$store.commit('cart_items', { value: v });


        } catch (e) {}
      }).catch(error => {
        this.$toast.error(this.$t('shop_not_ready'), { position: "top" });
      });
    },
    fetchData() {
      let KIOSK_URL = this.$store.state.my_env('kiosk');
      let SMARTORDER_URL = this.$store.state.my_env('smartorder');

      var u = location.host;
      var n = u.indexOf('.' + KIOSK_URL);
      if (n > 0) {
        var a = u.substring(0, n).trim();
        this.load2(a);
        return;
      }
      n = u.indexOf('.' + SMARTORDER_URL);
      if (n > 0) {
        var a = u.substring(0, n).trim();
        this.load2(a, true);
        return;
      }

      var that = this;

      // kiosk
      this.$store.commit('kiosk_mode', { value: true });

      loadScript('config/display_setting.js')
          .then(() => {
            if (window.display_info.display_orientation == 'landscape') {
              this.$store.commit('vertical', { value: false });
              this.$store.commit('banner_ratio', { value: '50%' });
              this.$store.commit('banner_ratio_right', { value: '50%' });
            } else {
              this.$store.commit('vertical', { value: true });
            }
            if (window.display_info.dev) {
              this.$store.commit('myshop_host', { value: 'https://m.myshop-dev.in' });
              this.$store.commit('kiosk_host', { value: 'https://kiosk-api.myshop-dev.in' });
            } else {
              this.$store.commit('myshop_host', { value: 'https://my.shockping.com' });
              this.$store.commit('kiosk_host', { value: 'https://kiosk-api.snadx.com' });
            }
            this.$store.commit('default_font_size', { value: window.display_info.font_size });

            loadScript('config/offline.js')
                .then(() => {
                  loadScript('config/products.js')
                      .then(() => {
                        var a = {};
                        for (let el of window.all_products) {
                          const code = el['barcode'];
                          if (code) {
                            a[code] = el;
                          }
                        }

                        that.$store.commit('products', { value: a });
                        a = null;

                        loadScript('config/categories.js')
                            .then(() => {
                              loadScript('config/options.js')
                                  .then(() => {
                                    try {
                                      var b = {};
                                      for (let el of window.all_options) {
                                        const oid = el['id'];
                                        if (oid) {
                                          b[oid] = el;
                                        }
                                      }

                                      that.$store.commit('prod_options', { value: b });
                                      b = null;
                                    } catch (e) {}

                                    loadScript('config/option_mappings.js')
                                        .then(() => {
                                          try {
                                            var c = {};
                                            for (let el of window.option_mappings) {
                                              const mapping = el['id'];
                                              if (mapping) {
                                                c[mapping] = el;
                                              }
                                            }

                                            that.$store.commit('option_mappings', { value: c });
                                            c = null;
                                          } catch (e) {}

                                          loadScript('config/cproducts.js')
                                              .then(() => {
                                                loadScript('config/shop.js')
                                                    .then(() => {
                                                      loadScript('config/session.js')
                                                          .then(() => {
                                                            var data = window.all_categories;
                                                            data.splice(0, 0, {
                                                              cid: 0,
                                                              name: '전체'
                                                            });

                                                            that.$store.commit('category_data', { value: data });
                                                            that.getOfflineInfo();

                                                            {
                                                              let url = "https://core-api-v2.shockping.com/core-api/lang/get/codes?id=" + window.myshop_info.shopid;
                                                              //let url = "/core-api/lang/get/codes?id=" + window.myshop_info.shopid;
                                                              axios.get(
                                                                  url,
                                                                  {
                                                                    headers: {
                                                                      KIOSK_ID: window.session_info.pos_num,
                                                                      KIOSK_PW: window.session_info.license,
                                                                    },
                                                                    timeout: 3000
                                                                  }
                                                              ).then(res => {
                                                                that.$store.commit('lang_codes', { value: res.data });
                                                              })
                                                            }

                                                            let url = "https://core-api-v2.shockping.com/core-api/main/get/info/skin?id=" + window.myshop_info.shopid;
                                                            //let url = "/core-api/main/get/info/skin?id=" + window.myshop_info.shopid;
                                                            axios.get(
                                                                url,
                                                                {
                                                                  headers: {
                                                                    KIOSK_ID: window.session_info.pos_num,
                                                                    KIOSK_PW: window.session_info.license,
                                                                  },
                                                                  timeout: 3000
                                                                }
                                                            ).then(res => {
                                                              that.shopinfo = res.data;
                                                              that.$store.commit('shop_info', { value: that.shopinfo });


                                                              // info
                                                              that.$store.commit('lang_default_code', { value: that.shopinfo.lang_default_code });

                                                              // 현재 선택 코드?
                                                              if(!that.lang_current_code)
                                                                that.$store.commit('lang_current_code',{value:that.shopinfo.lang_default_code})
                                                            })
                                                          })
                                                          .catch(() => {});
                                                    });
                                              });
                                        });
                                  });
                            });
                      })
                      .catch(() => {
                        location.href = "index.html";
                      });
                });
          });
    },
    getOfflineInfo() {
      var that = this;
      axios.get(this.myshop_host + '/api/offline/get/offline_info', {
        headers: {
          KIOSK_ID: window.session_info.pos_num,
          KIOSK_PW: window.session_info.license,
        },
        timeout: 3000
      }).then(res => {
        that.$store.commit('offline_info', { value: res.data });
        that.$store.commit('parsing_ok', { value: true });
      }).catch(error => {
        that.$store.commit('offline_info', { value: window.offline_info });
        that.$store.commit('parsing_ok', { value: true });
      }).finally(() => {
        if (this.offline_info.open_hm == this.offline_info.close_hm) {
          this.emitter.emit("close", false);
        } else {
          let date = new Date();
          let hour = date.getHours();
          hour = hour >= 10 ? hour : '0' + hour;
          let min = date.getMinutes();
          var hhmm = hour.toString() + min.toString();
          if (hhmm >= this.offline_info.open_hm && hhmm <= this.offline_info.close_hm) {
            this.emitter.emit("close", false);
          } else {
            this.emitter.emit("close", true);
          }
        }
      });
    },
  }
};
</script>
<style>
.c-toast-container {
  z-index: 99999 !important;
}

.smart_order {
  font-size: 13px;
}

.kiosk {
  font-size: 15px;
}

.mbutton {
  background-color: white !important;
}
.button-grey:focus, .button-grey:hover {
  color: #fff;
  background: #697181;
  border-color: #697181;
}
.button-grey.button-border {
  color: #5c6370;
  background: transparent;
}
.button:focus, .button:hover, button:focus, button:hover {
  background: #f9f9f9;
  border-color: #d0d2d7;
}
.button, .button:focus, .button:hover, button, button:focus, button:hover {
  color: #2e3138;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.button-grey {
  color: #fff;
  background: #5c6370;
  border-color: #5c6370;
}
.button-border {
  background: transparent;
}
.button, .mbutton {
  display: inline-block;
  padding: calc((1.5rem - 2px)/2) 1em;
  min-width: 2.5rem;
  height: 2.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1;
  font-size: 0.9rem;
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d5d7dd;
  border-radius: 0.25em;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

/* css color */
/*
pri
  ff1b81
  seco
  4d3750
alt
  #FFA500
 */
:root {
  /*--qro-primary-color: #ff1b81;*/
  --qro-primary-color: #ffffff;
  --qro-secondary-color: #4d3750;
  --qro-alt-color1: #FFA500;
}
</style>