<template>
  <div v-if="ad_visible" :id="'ad_layer_'+id">

    <!-- video AD start -->
    <link href="//storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/adVideo3.css" rel="stylesheet">
    
    <div class="ad" style="margin-top: 10px;z-index: 1" :adid="id">
      <div class="adWrap" :adid="id">
        <div class="adCover" :adid="id">
          <img :adid="id" class="bg" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/bg.jpg" />
          <div :adid="id" class="adVideoWrap">
            <video :adid="id" class="adVideo" poster="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/poster.jpg" playsinline muted >
              <source src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/video3.mp4" type="video/mp4" />
            </video>
            <div class="volumeButton" :adid="id">
              <img :adid="id" class="volumeOn" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/volumeOn.png" />
              <img :adid="id" class="volumeOff" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/volumeOff.png" />
            </div>
            <img :adid="id" class="pauseButton" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/button.png" />
          </div>

          <img :adid="id" class="posterBt" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/posterBt.png" />
          <img :adid="id" class="closeBt" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/closeBt.png" />
          <img :adid="id" class="logo1" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/logo1.png" />
          <img :adid="id" class="text" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/text.png" />
          <img :adid="id" class="obj" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/obj2.png" />
          <div :adid="id" class="dragBtWrap">
            <div :adid="id" class="dragBt">
              <img :adid="id" class="arrow" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/arrow.png" />
            </div>
          </div>
          <div :adid="id" class="touchIconWrap">
            <img :adid="id" class="touchIcon" src="https://storage0.mocoplex.com/cco/project/mocoplex/disney/adVideo3Res/touch.png" />
          </div>
        </div>
      </div>
    </div>
    <div :id="'load_js_ad_'+ id"></div>
    <!-- video AD end -->

  </div>

</template>

<script>
import { ref, onMounted } from 'vue';
import {mapGetters} from "vuex";

export default {
  computed: mapGetters({
    smartorder_mode:'smartorder_mode',
    ad_visible:"ad_visible"
  }),
  mounted() {
    //console.log('aaaa')
    this.loadVideoAd()
  },
  created() {
  },
  props:{
    id:{
      default:'',
      type:String
    }
  },
  methods: {
    loadVideoAd: function () {
      //if(!this.ad_visible)
        //return

      try {
        new AdModule(this.id);
        return;
      } catch (e) {
      }

      /*

      const script = document.createElement('script');
      //script.src = 'https://storage0.mocoplex.com/qro/js/video.js'; // Replace with your actual script URL
      script.src = '/js/video.js'; // Replace with your actual script URL
      script.id = 'ad-js-script';

      var id = this.id
      script.onload = function () {
        new AdModule(id);
      }
      //script.async = true;

      if (typeof AdModule !== 'undefined') {
        new AdModule(id);
        return
      }

      var el = document.getElementById('load_js_ad_'+this.id);
      if (el) {
        el.appendChild(script)
        //this.video_loaded = true
        //console.log(el)
      }
*/
    }
  }
}
</script>