import {createRouter, createWebHashHistory} from 'vue-router'

import store from "../store"
const router = new createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path:'/',
            redirect: '/mhome',
        },
        {
            path: '/torder-error',
            component: () => import('@/components/page/TorderError'),
        },
        {
            path: '/tablet_main',
            component: () => import('@/components/page/TabletMain'),
        },
        {
            path: '/waiting_main',
            component: () => import('@/components/page/WaitingMain'),
        },
        {
            path: '/tablet_game',
            component: () => import('@/components/page/TabletGame'),
        },
        {
            path: '/point_main',
            component: () => import('@/components/page/PointMain'),
        },
        {
            path: '/point_save',
            component: () => import('@/components/page/PointSaving'),
        },
        {
            path: '/point_use',
            component: () => import('@/components/page/PointUsing'),
        },
        {
            path: '/status',
            component: () => import('@/components/page/StatusPage'),
        },
        {
            path: '/home',
            component: () => import('@/components/page/HomePage'),
        },
        {
            path: '/ad-list',
            component: () => import('@/components/page/AdList'),
        },
        {
            path: '/mhome',
            component: () => import('@/components/page/MenuHomePage'),
        },
        {
            path: '/menu',
            component: () => import('@/components/page/MenuPage'),
        },
        {
            path: '/smenu',
            component: () => import('@/components/page/SimpleMenu'),
        },
        {
            path: '/waiting',
            component: () => import('@/components/page/Waiting'),
        },
        {
            path: '/review',
            component: () => import('@/components/page/ReviewEvent'),
        },
        {
            path: '/review_receipt',
            component: () => import('@/components/page/ReviewEventReceipt'),
        },
        {
            path: '/waiting-status',
            component: () => import('@/components/page/WaitingStatus'),
        },
        {
            path: '/top',
            component: () => import('@/components/page/TopPage'),
        },
        {
            path: '/cart',
            component: () => import('@/components/page/CartPage'),
        },
        {
            path: '/order-status',
            component: () => import('@/components/page/OrderStatus'),
        },
        {
            path: '/init',
            component: () => import('@/components/page/init'),
        },
        {
            path: '/pos_main',
            component: () => import('@/components/page/PosMain'),
        },
    ],
})

var getCookie2 = function(name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == name) {
            return unescape(y);
        }
    }
}


router.beforeEach(async(to, from, next) => {

    let KIOSK_URL = store.state.my_env('kiosk')
    let SMARTORDER_URL = store.state.my_env('smartorder')

    //console.log(KIOSK_URL)
    //console.log(SMARTORDER_URL)

    if(to.fullPath.indexOf('/smenu')==0) {
        store.commit('simple_menu', {value:true})
        store.commit('menu_page', {value:true})
    } else {
        store.commit('simple_menu', {value:false})
        store.commit('menu_page', {value:false})
    }

    if(to.fullPath.indexOf('/menu')==0) {
        store.commit('menu_page', {value:true})
    }

    let app_event = function (cmd, data){
        try {
            if(window.FlutterWebView) {
                var message = {
                    command: cmd,
                    data: data
                }
                var msg = JSON.stringify(message)
                window.FlutterWebView.postMessage(msg)
            }else if(window.MyshopKioskWebView) {
                var message = {
                    command: cmd,
                    data: data
                }
                var msg = JSON.stringify(message)
                window.MyshopKioskWebView.getWebMessage(msg)
            }
        } catch (ex) {
            console.log(ex)
        }
    }

    if(to.fullPath.indexOf('/order-status')==0) {
        store.commit('torder_status', {value:true})
    } else {
        store.commit('torder_status', {value:false})
    }

    if(to.fullPath.indexOf('/waiting_main')==0 || to.fullPath.indexOf('/tablet_main')==0 || to.fullPath.indexOf('/tablet_game')==0 || to.fullPath.indexOf('/point_main')==0 || to.fullPath.indexOf('/point_save')==0|| to.fullPath.indexOf('/point_use')==0) {
        //app_event('tablet_show_adview')
    } else {
        app_event('tablet_hide_adview')
    }

    if(to.fullPath.indexOf('/mhome')==0) {
        if(location.host.indexOf("."+KIOSK_URL)>=0) {
            next('/menu')
            return
        }
    }

    {
        let sid = getCookie2('APP-SID')
        let skey = getCookie2('APP-SKEY')

        let admin_pos = getCookie2('ADMIN-POS')
        let admin_tale = getCookie2('ADMIN-TABLE')

        try {
            admin_tale = parseInt(admin_tale)
            if(admin_tale) {
                admin_tale = 1
            } else {
                admin_tale = 0
            }
        } catch(e) {
            admin_tale = 0
        }
        try {
            admin_pos = parseInt(admin_pos)
            if(admin_pos) {
                admin_pos = 1
            } else {
                admin_pos = 0
            }
        } catch(e) {
            admin_pos = 0
        }


        //sid
        if(sid != null && skey != null) {
            store.commit('sid', {value:sid})
            store.commit('skey', {value:skey})
            if(admin_pos)
                store.commit('admin_pos', {value:true})
            if(admin_tale) {
                store.commit('admin_pos', {value:true})
                store.commit('admin_table', {value:true})
            }
        } else {
            sid = getCookie2('sid')
            skey = getCookie2('skey')
            if(sid != null && skey != null) {
                store.commit('sid', {value: sid})
                store.commit('skey', {value: skey})
                if (admin_pos)
                    store.commit('admin_pos', {value: true})
                if (admin_tale) {
                    store.commit('admin_pos', {value: true})
                    store.commit('admin_table', {value: true})
                }
            }
        }
    }

    const turl = new URL(location.href);
    // table_no - 파라메터로 전달된 경우
    if(turl.searchParams.get('agent_pos') && turl.searchParams.get('table_no')) {
        store.commit('admin_table', {value:true})
        store.commit('admin_pos', {value:true})
    }
    if(turl.searchParams.get('kiosk_pos_mode')) {
        store.commit('kiosk_pos_mode', {value:1})
    }
    if(turl.searchParams.get('kiosk_name')) {
        store.commit('kiosk_name', {value:turl.searchParams.get('kiosk_name')})
    }

    // torder_no
    var t_tid = getCookie2('torder-tid')
    var t_ts = getCookie2('torder-ts')
    var t_sid = getCookie2('torder-sid')


    if(t_sid) {
        store.commit('t_sid', {value:t_sid})
    }
    var loc = location.href

    if(to.fullPath.indexOf('/ad-list')>=0 || to.fullPath.indexOf('/waiting_main')>=0 || to.fullPath.indexOf('/point_main')>=0 || to.fullPath.indexOf('/point_save')>=0 || to.fullPath.indexOf('/point_use')>=0
        || to.fullPath.indexOf('/tablet_main')>=0 || to.fullPath.indexOf('/tablet_game')>=0 || to.fullPath.indexOf('/review')>=0 || to.fullPath.indexOf('/order-status')>=0) {
        store.commit('hide_info', {value:true})
    } else {
        store.commit('hide_info', {value:false})
    }

    // 스마트오더 여부 확인
    if(loc.indexOf(KIOSK_URL)>=0) {
        store.commit('smartorder_mode', {value: true})

        let smartorder_info = {
            mode:'pickup',
            phone:'',
            address1:'',
            address2:'',
            zipcode:'',
            memo:'',
            email:'',
        }
        // 파라메터 파싱
        if(turl.searchParams.get('smartorder_mode')) {
            smartorder_info.mode = turl.searchParams.get('smartorder_mode')
        }
        if(turl.searchParams.get('smartorder_phone')) {
            smartorder_info.phone = turl.searchParams.get('smartorder_phone')
        }
        if(turl.searchParams.get('smartorder_address1')) {
            smartorder_info.address1 = turl.searchParams.get('smartorder_address1')
        }
        if(turl.searchParams.get('smartorder_address2')) {
            smartorder_info.address2 = turl.searchParams.get('smartorder_address2')
        }
        if(turl.searchParams.get('smartorder_zipcode')) {
            smartorder_info.zipcode = turl.searchParams.get('smartorder_zipcode')
        }
        if(turl.searchParams.get('smartorder_memo')) {
            smartorder_info.memo = turl.searchParams.get('smartorder_memo')
        }
        if(turl.searchParams.get('smartorder_email')) {
            smartorder_info.email = turl.searchParams.get('smartorder_email')
        }

        store.commit('smartorder_detail', {value: smartorder_info})
    }

    //console.log(to.fullPath)
    if(loc.indexOf(SMARTORDER_URL)>0 && (to.fullPath.indexOf('/torder-error')<0 && to.fullPath.indexOf('/order-status')<0) && to.fullPath.indexOf('/ad-list')<0) {
        var top_menu = '/menu'

        try {
            if(store.state.hide_info) {
                // ok
            } else {
                if(!t_tid)
                    throw 'e'
                if(!t_ts)
                    throw 'e'

                var now = new Date().getTime()
                t_ts = parseInt(t_ts)
                if(isNaN(t_ts) || t_ts == undefined || t_ts == null) {
                    t_ts = 0
                }

                var es = now - t_ts
                es = parseInt(es / 1000)

                // 3 시간
                //if(es > 12 * 60 * 1000 * 60 || es < 0) {
                var exp = 3 * 60  * 60

                //console.log(es + ' ' + exp)
                if(es > exp) {
                    throw 'e'
                }
            }

            // t-order
            store.commit('torder_no', {value:t_tid})

            if(to.fullPath.indexOf('/mhome')==0) {
                if(location.host.indexOf("."+SMARTORDER_URL)>=0) {
                    // 사진 메뉴로 우선
                    next(top_menu)
                    return
                }
            }
        } catch(e) {


            console.log(e)
                if(store.state.tablet_auth) {
                } else {
                    store.commit('torder_error', {value:true})
                }

                if(to.fullPath.indexOf('/mhome')==0) {
                    if(location.host.indexOf("."+SMARTORDER_URL)>=0) {
                        // 사진 메뉴로 우선
                        next(top_menu)
                        return
                    }
                }

        }
    }
    else {
        // ok
        store.commit('force_valid', {value:true})
    }

    //console.log(t_tid + " " + t_ts + " " + loc)

    /*
        var url = '/api/myshp/userinfo'
        await axios.get(url)
            .then(data => {
                var a = data.data
                if(a.uid==67) {
                    store.commit('uid', a.uid)
                }
                if (a.uid) {
                    store.commit('login', 1)
                    store.commit('login_data', a)
                    next();
                } else {
                    throw 'e'
                }
            }).catch(err => {
                // 로그인 실패
            })
    */

    //if(to.mhome)
    next();
})

export default router
